@import '../../../theme/colors';

.signin {
  max-width: 420px;
  &__title {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    margin-top: 8px;
    margin-bottom: 12px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }
  &__link {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    a {
      color: white;
      font-weight: 900;
    }
  }
}
