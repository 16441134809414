@import '../../theme/colors';

.adminRouter {
  height: calc(100%-120px);
  position: 'relative';
  &__adminContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
  }
  &__logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    img {
      width: 160px;
    }
  }
  &__leftMenu {
    width: 300px;
    height: 100vh;
    background-color: $greyLight;
  }
  &__mainContent {
    flex: 1;
    overflow-y: auto;
  }

  &__menuContent {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.5s;
    background-color: $greyLight;
    &:last-child {
      border-bottom: 1px solid $greyLighter !important;
    }
  }

  &__menuContentoption {
    padding-bottom: 18px;
    padding-top: 18px;
    text-decoration: none;
    font-size: 16px;
    color: white;
    display: block;
    transition: 0.3s;
    text-align: left;
    padding-left: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none !important;
    border-top: 1px solid $greyLighter !important;
    outline-offset: 0 !important;
    outline: 0 !important;
    span {
      margin-left: 10px;
    }
  }
  &__menuContentoption:hover {
    text-decoration: none;
    background-color: $greyRegular;
  }
}

.adminList {
  &__buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }
  &__dataContainer {
    padding: 50px;
  }
  &__data {
    margin: 20px 0;
  }
  &__dataLabel {
    font-size: 24px;
    font-weight: 200;
    color: $primaryRegular;
    margin-bottom: 5px;
  }
  &__dataValue {
    font-size: 26px;
    font-weight: 900;
  }
  &__dataDivider {
    width: 100%;
    border-bottom: 1px solid $primaryRegular;
  }
  &__imgCard {
    display: flex;
    flex-direction: row;
  }
  &__imgCardContainer {
    display: flex;
    padding: 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #34293e;
    border-radius: 12px;
    margin-bottom: 12px;
    max-width: 400px;
    min-width: 300px;
    margin-right: 10px;
  }
  &__imgCardImg img {
    width: 44px;
    height: 67px;
    border-radius: 6px;
  }
  &__imgCardText {
    flex: 1;
    padding: 0px 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  &__imgCardBtn {
    width: 52px;
  }
  &__table {
    border-collapse: collapse;
    tr {
      border-bottom: solid 5px $greyRegular;
      td,
      th {
        margin-bottom: 5px;
        padding: 8px 20px;
        font-size: 22px;
        vertical-align: center;
        text-align: left;
      }
      td {
        font-weight: 200;
        background-color: $greyLight;
        &:first-of-type {
          border-radius: 15px 0 0 15px;
        }
        &:last-of-type {
          border-radius: 0 15px 15px 0;
        }
      }
    }
  }
  &__buttonList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__popupContainer {
    background-color: $greyRegular;
    border-radius: 16px;
    min-width: 400px;
    min-height: 400px;
    padding: 30px;
  }
}

body {
  //overflow-y: scroll;
}

.popup-content {
  border: none !important;
  background-color: transparent !important;
  padding: 0;
  width: auto !important;
  @media screen and (max-width: 800px) {
  }
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.react-select-container {
}
.react-select__control {
  height: 48px;
  border-radius: 24px !important;
  padding: 0 10px;
}
.react-select__menu {
  //border-radius: 24px !important;
}
.react-select__option {
  color: $greyRegularDarker !important;
}
