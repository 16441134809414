@import '../../theme/colors';

.offlineLayout {
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;

  &__right {
    width: 50%;
    height: 100vh;
    background: url('./Group\ 3.png');
    background-size: cover;

    @media screen and (max-width: 900px) {
      width: 35%;
    }
    @media screen and (max-width: 700px) {
      width: 0%;
    }
  }
  &__left {
    width: 50%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: 900px) {
      width: 65%;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(180deg, #34293e 0%, rgba(52, 41, 62, 0.25) 100%), url('./Group\ 3.png');
      background-size: cover;
    }
  }
  &__logo {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 200px;
    margin-bottom: 20px;
  }
  &__container {
    //background-color: $greyRegularDarker;
    display: flex;
    align-self: center;
    border-radius: 10px;
    padding: 20px;
  }
  &__containerLang {
    display: flex;
    margin-top: 15px;
  }
}
