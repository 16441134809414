$greyRegular: #281e30;
$greyRegularDarker: #1a1320;
$greyDark: #212121;
$greyLight: #34293e;
$greyLightHover: #483c53;
$greyLightOppacity: #ffffff20;
$greyLightLessOppacity: #ffffff50;
$greyLighter: #4a4a4a;
$greyDarkest: #000000;

$primaryRegular: #49ffc1;
$primaryDark: #47097e;
$primaryLight: #49ffc1;
$primaryLighter: #c789fe;

$white: #ffffff;
$error: #ff4d66;
$success: #00d377;
