@import '../../../../theme/colors';

.headerscorm {
  height: 88px;
  max-height: 88px;
  width: 100%;
  z-index: 1040;
  background-color: $greyRegularDarker;
  display: flex;
  flex: 1 1;
  justify-content: center;
  &__container {
    display: flex;
    justify-content: space-between;
    vertical-align: center;
    align-items: center;

    flex: 1 1 auto;
    //max-width: 1200px;
  }
  &__sideBarContainer {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    z-index: 1044;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px 0px 0px 24px;
  }
  &__sideBar {
    height: 100%;
    background-color: #281e30;
    z-index: 1045;
    width: 360px;
    display: flex;
    flex-direction: column;
  }
  &__sideBarMenu {
    flex: 1;
  }
  &__sideBarGrey {
    flex: 1;
  }
  &__sideBarMenuButton {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 12px;
  }
  &__sideBarMenuList {
    padding: 12px;
  }
  &__logo-box {
    align-self: center;
    vertical-align: center;
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
  &__logo-boxtext {
    border-left: 1px solid white;
    padding-left: 18px;
    margin-left: 18px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &__logo {
    width: 120px;
    margin-left: 30px;
  }

  &__options {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  &__usermenu {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 1020;
    background-color: $greyLight;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 20px;
    cursor: pointer;
    transition: transform 300ms;
  }
  &__usermenu:hover {
    transform: scale(1.1);
  }
  &__premiumCompany {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $greyLight;
    border-top-left-radius: 5px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 20px;
    max-height: 40px;
    cursor: pointer;
    span {
      padding: 0 8px 0 10px;
      color: #fff;
      font-size: 18px;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  &__premiumCompanyLogo {
    max-height: 40px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      transition: transform 300ms;
    }
  }

  &__premiumCompanyText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__premiumCompanyText1 {
    font-weight: 100;
    font-size: 12px;
  }
  &__premiumCompanyText2 {
    padding: 0 8px 0 10px;
    color: #fff;
    font-size: 16px;
  }

  &__sponsorPopup {
    height: auto;
    width: 250px;
    position: absolute;
    z-index: 1080;
    top: 65px;
    right: 76px;
    background-color: $greyRegular;
    border-radius: 5px;
    padding: 20px;
    @media screen and (min-width: 1200px) {
      right: calc(((100vw - (100vw - 100%) - 1050px) / 2));
    }
    &:before {
      content: '\A';
      border-style: solid;
      border-width: 0 7px 10px 7px;
      border-color: transparent transparent $greyLight transparent;
      position: absolute;
      top: -10px;
      right: 18px;
    }
    a {
      cursor: pointer;
      border: none !important;
      border-top: 1px solid $greyLighter !important;
      outline-offset: 0 !important;
      outline: 0 !important;
      text-decoration: none;
      color: $primaryRegular;
    }
  }
  &__sideRessourceContainer {
    display: flex;
    padding: 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #34293e;
    border-radius: 12px;
    margin-bottom: 12px;
  }
  &__sideRessourceImg img {
    width: 44px;
    height: 67px;
    border-radius: 6px;
  }
  &__sideRessourceText {
    flex: 1;
    padding: 0px 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  &__sideRessourceBtn {
    width: 52px;
  }
}
