@import '../../../theme/colors';

.greenLight {
  margin-top: 15px;
  margin-bottom: 5px;
  span {
    letter-spacing: 1.5px;
    color: $primaryRegular;
    font-size: 11px;
    line-height: 13px;
    font-weight: 100;
  }
}
