@import '../../theme/colors';
.button {
  &__base {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    height: 48px;
    padding: 0px 28px;
    width: fit-content;
    border-radius: 24px;
    transition: all 1s ease;

    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
      transform: translateY(1px);
    }
    svg {
      margin-right: 6px;
    }
  }
  &__primary {
    background-color: $primaryRegular;
    color: $greyRegular;
  }
  &__grey {
    background-color: $greyRegularDarker;
    color: $white;
  }
  &__dark {
    background-color: #34293e;
    color: $white;
  }
  &__locked {
    background-color: $greyLight;
    color: rgba($white, 0.7);
    border: 1px solid rgba($white, 0.7);
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 1;
      transform: none;
    }
  }
  &__greyLight {
    background-color: #34293e;
    color: $white;
    &:hover {
      background: rgba(73, 255, 193, 0.2);
      color: $primaryRegular;
      opacity: 1;
    }
  }
  &__error {
    background-color: #610f0f;
    color: $white;
    &:hover {
      background: #b61919;
      color: $white;
      opacity: 1;
    }
  }
  &__loaderimg {
    width: 20px !important;
    height: 20px !important;
    margin: 0 !important;
  }
}
