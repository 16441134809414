@import '../../theme/colors';

.langpicker {
  &__container {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  &__flagContainer {
    padding: 3px;
    background-color: $greyRegular;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    img {
      width: 26px;
      height: 20px;
      border-radius: 3px;
      margin: 0;
    }
  }
  &__second {
    border-radius: 0 5px 5px 0;
  }
  &__first {
    border-radius: 5px 0 0 5px;
  }
  &__flagFade {
    opacity: 0.3;
    height: 20px;
    cursor: pointer;
  }
  &__flagFadeOff {
    height: 24px;
  }
  &__flagFade:hover {
    opacity: 1;
  }
  &__current {
    padding: 5px;
    background-color: $greyLight;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    img {
      width: 32px;
      height: 24px;
      border-radius: 3px;
    }

    &__flagFade {
      opacity: 1;
    }
  }
}
