@import '../../../theme/colors';

.ahome {
  height: calc(100% - 70px);
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding: 0 50px;
  @media screen and (max-width: 750px) {
    padding: 0 20px;
  }
  &__title {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 19px;
    text-transform: uppercase;

    color: #49ffc1;
    margin-top: 20px;
    margin-bottom: 1px;
    margin-bottom: 20px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  &__titlecontainer {
    font-weight: 100;
    font-size: 32px;
    color: $primaryRegular;
    margin: 10px 0 5px 0;
  }
  &__cardcontainer {
    display: flex;
    width: calc(100%-20px);
    height: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  &__cohort {
    position: relative;
    width: 100%;

    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 24px;
    padding: 1px;
    margin-bottom: 15px;
  }
  &__cohortSubContainer {
    background-size: cover;
    border-radius: 24px;
  }
  &__cohortContainer {
    background: rgba(38, 32, 45, 0.8);
    border: 4px solid #ffffff;
    backdrop-filter: blur(15px);
    padding: 11px;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
  }
  &__cohortImgContainer {
    width: 166px;
    height: 150px;
    background-size: cover;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 130px;
      max-width: 130px;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &__cohortOtherContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 24px;
  }
  &__cohortOtherTop {
    flex: 1;
    display: flex;

    @media screen and (max-width: 750px) {
      flex-direction: column-reverse;
    }
  }
  &__cohortOtherTopLeft {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__cohortOtherTopLeftT0 {
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 5px;
    color: $primaryRegular;
  }
  &__cohortOtherTopLeftT1 {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 5px;
  }
  &__cohortOtherTopLeftT2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
  }
  &__cohortOtherTopLeftT3 {
  }
  &__cohortOtherTopLeftT3P {
    height: 20px;
    left: 202px;
    top: 97px;
    background: rgba(38, 32, 45, 0.2);
    border: 0.714286px solid #ffffff;
    border-radius: 17px;
    width: fit-content;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    display: flex;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
  }
  &__cohortOtherTopLeftT3G {
    color: #49ffc1;
    height: 20px;
    left: 202px;
    top: 97px;
    background: rgba(73, 255, 193, 0.25);
    border: 0.714286px solid #49ffc1;
    border-radius: 17px;
    width: fit-content;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    display: flex;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
  }
  &__cohortOtherTopRight {
    width: 100px;
    padding-right: 8px;
    @media screen and (max-width: 750px) {
      margin-bottom: 12px;
    }
  }
  &__cohortOtherTopRightText {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;

    text-align: right;
    text-transform: uppercase;

    color: #49ffc1;
    margin-bottom: 10px;

    @media screen and (max-width: 750px) {
      text-align: left;
    }
  }
  &__cohortOtherTopRightBar {
    width: 100px;
    height: 4px;
    border: 1px solid #49ffc1;
    border-radius: 24px;
  }
  &__cohortOtherTopRightBarBar {
    height: 4px;
    border-radius: 24px;
    background-color: #49ffc1;
  }
  &__cohortOtherBottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 0;
    }
  }
  &__cohortOtherBottomLeft {
    flex: 1;
  }
  &__cohortOtherBottomLeftT1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 6px;
  }
  &__cohortOtherBottomLeftT2 {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
  }
  &__cohortOtherBottomRight {
    @media screen and (max-width: 750px) {
      margin-top: 12px;
    }
  }
  &__cohortVisioLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 24px;
  }
  &__cohortVisioLinkTitle {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    margin-right: 30px;
    align-items: center;
    justify-content: center;
    color: #26202d;
    svg {
      margin-right: 8px;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &__cohortVisioLinkContainer {
    flex: 1;
    display: flex;

    background: rgba(38, 32, 45, 0.1);
    border: 1px solid rgba(38, 32, 45, 0.1);
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    margin-left: 18px;
    padding: 6px 12px;
    @media screen and (max-width: 600px) {
      margin-left: 0px;
    }
  }
  &__cohortVisioLinkLink {
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #000000;
  }
  &__cohortVisioLinkButton {
  }
}
